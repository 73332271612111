
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    buttonMessage: {
      type: String,
    },
    allFilled: {
      type: Boolean,
    },
    schedule: {
      type: String,
    },
  },
})
