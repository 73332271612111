import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_compose_side_button = _resolveComponent("compose-side-button")!
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, { class: "d-flex" }, {
    menu: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
        return (_openBlock(), _createBlock(_component_tm_dropdown_item, { key: i }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_person, {
              name: item.label,
              "avatar-color": item.avatarColor,
              "avatar-size": "small",
              "avatar-placeholder-custom-size": item.type === 'phone' ? '12px' : '14px',
              "semi-bold": ""
            }, null, 8, ["name", "avatar-color", "avatar-placeholder-custom-size"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_compose_side_button, {
        icon: "schedule",
        label: "Frequently sent"
      })
    ]),
    _: 1
  }))
}