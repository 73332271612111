
import { defineComponent, ref, watch } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmRepeatForm from '@/components/shared/TmRepeatForm.vue'
import { endOptions, repeatOptions } from '@/definitions/_general/_data/frequencyOptions'
import { utcZonesOptions } from '@/definitions/_general/_data/utc'

export default defineComponent({
  components: {
    TmRepeatForm,
    TmFormLine,
  },
  props: {
    schedule: {
      type: String,
    },
  },
  emits: ['update:schedule'],
  setup(props, context) {
    const scheduleValue = ref(props.schedule)
    const sendDate = ref(new Date('2022-04-19'))
    const sendHours = ref('13')
    const sendMinutes = ref('00')
    const timezone = ref(utcZonesOptions[16])
    const repeat = ref({
      currentRepeat: repeatOptions[0],
      currentEnd: endOptions[0],
      repeatCount: 1,
      smsSessions: 1,
    })

    watch(() => props.schedule, (newValue) => {
      scheduleValue.value = newValue
    })

    watch(() => scheduleValue.value, (newValue) => {
      context.emit('update:schedule', newValue)
    })

    return {
      scheduleValue,
      sendDate,
      sendHours,
      sendMinutes,
      timezone,
      repeat,
    }
  },
})
