
import { defineComponent } from 'vue'
import ComposeSideButton from '@/components/shared/compose/sideButtons/ComposeSideButton.vue'
import useComposeOpenModal from '@/compositions/compose/useComposeOpenModal'

export default defineComponent({
  name: 'ComposeSideButtonAttachFile',
  components: { ComposeSideButton },
  setup() {
    const { openModalAttachFile } = useComposeOpenModal()

    return {
      openModalAttachFile,
    }
  },
})
