
import { defineComponent, ref } from 'vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStepPreview from '@/components/shared/templates/FlowStepPreview.vue'
import SendField from '@/components/pages/campaigns/smsFlow/SendField.vue'
import { destinations } from '@/definitions/campaigns/data'
import useSmsFlow from '@/compositions/smsFlow'

export default defineComponent({
  components: {
    TmFlag,
    TmButton,
    FlowStepPreview,
    SendField,
  },
  setup() {
    const { closeSteps, destinationSelected, updateDestinationSelected } = useSmsFlow()
    const destinationsValue = ref(destinations)
    const destinationsSelectedValue = ref([...destinationSelected.value])
    const onDiscard = () => {
      destinationsSelectedValue.value = [...destinationSelected.value]
    }
    const stepFunction = () => {
      updateDestinationSelected(destinationsSelectedValue.value)
      closeSteps()
    }
    return {
      destinationsValue,
      destinationsSelectedValue,
      destinationSelected,
      onDiscard,
      stepFunction,
    }
  },
})
