
import { defineComponent, ref } from 'vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAutoSizeBadges from '@/components/shared/autoSize/TmAutoSizeBadges.vue'
import FlowStepPreview from '@/components/shared/templates/FlowStepPreview.vue'
import RecipientsEditor from '@/components/shared/compose/RecipientsEditor.vue'
import { formatNumber } from '@/services/utils'
import useSmsFlow from '@/compositions/smsFlow'

export default defineComponent({
  components: {
    TmBadge,
    TmButton,
    TmAutoSizeBadges,
    FlowStepPreview,
    RecipientsEditor,
  },
  setup() {
    const { recipients, updateRecipients, finishStep } = useSmsFlow()
    const recipientsValue = ref([...recipients.value])
    const onDiscard = () => {
      recipientsValue.value = [...recipients.value]
    }
    const stepFunction = () => {
      updateRecipients(recipientsValue.value)
      finishStep(1)
      finishStep(2)
    }
    return {
      recipients,
      recipientsValue,
      onDiscard,
      stepFunction,
      formatNumber,
    }
  },
})
