
import { defineComponent } from 'vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import SmsFlowStep1 from '@/components/pages/campaigns/smsFlow/SmsFlowStep1.vue'
import SmsFlowStep2 from '@/components/pages/campaigns/smsFlow/SmsFlowStep2.vue'
import SmsFlowStep3 from '@/components/pages/campaigns/smsFlow/SmsFlowStep3.vue'
import SmsFlowStep4 from '@/components/pages/campaigns/smsFlow/SmsFlowStep4.vue'
import SmsFlowPreview from '@/components/pages/campaigns/smsFlow/SmsFlowPreview.vue'
import useSmsFlow from '@/compositions/smsFlow'

export default defineComponent({
  components: {
    FlowStepList,
    SmsFlowStep1,
    SmsFlowStep2,
    SmsFlowStep3,
    SmsFlowStep4,
    SmsFlowPreview,
  },
  setup() {
    const { steps, recipients, currentStep, updateCurrentStep, nextActiveStep } = useSmsFlow()
    const onStepChange = (value: any) => {
      updateCurrentStep(value)
    }

    return {
      steps,
      recipients,
      currentStep,
      nextActiveStep,
      onStepChange,
    }
  },
})
