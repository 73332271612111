
import { defineComponent } from 'vue'
import ComposeSideButton from '@/components/shared/compose/sideButtons/ComposeSideButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmPerson from '@/components/shared/TmPerson.vue'

export default defineComponent({
  name: 'ComposeSideButtonFrequentlySent',
  components: { TmPerson, TmDropdown, TmDropdownItem, ComposeSideButton },
  setup() {
    const items = [
      { type: 'name', label: 'Guy Hawkins', avatarColor: 'orange' },
      { type: 'phone', label: '(603) 555-0123', avatarColor: 'purple' },
      { type: 'name', label: 'Darrell Steward', avatarColor: 'mint' },
      { type: 'phone', label: '(303) 555-0105', avatarColor: 'crimson' },
      { type: 'name', label: 'Jacob Jones', avatarColor: 'purple' },
      { type: 'name', label: 'Robert Fox', avatarColor: 'green' },
    ]

    return {
      items,
    }
  },
})
