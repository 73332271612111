
import { defineComponent } from 'vue'
import type { SizeProp } from '@/definitions/shared/types'
import kebabCase from 'lodash/kebabCase'

type BodySize = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'

export default defineComponent({
  name: 'ComposeFieldWrapper',
  props: {
    sideButtonsCenter: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<BodySize>,
      default: 'medium',
    },
  },
  setup() {
    return {
      kebabCase,
    }
  },
})
