import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dc5841c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "compose-field-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["compose-field-wrapper__body", `compose-field-wrapper__body--${_ctx.kebabCase(_ctx.size)}`])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    (_ctx.$slots['side-buttons'])
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["compose-field-wrapper__side-buttons", { 'compose-field-wrapper__side-buttons--centering': _ctx.sideButtonsCenter }])
        }, [
          _renderSlot(_ctx.$slots, "side-buttons", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}