
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import ComposeSideButton from '@/components/shared/compose/sideButtons/ComposeSideButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import type { TooltipPosition } from '@/compositions/renderTooltip'

const defaultItems = [
  'First name',
  'Last name',
  'Phone',
  'Email',
  'Company name',
  'Test field',
]

export default defineComponent({
  name: 'ComposeSideButtonInsertTag',
  components: { TmDropdownItem, TmDropdown, ComposeSideButton },
  props: {
    primaryColor: {
      type: Boolean,
    },
    position: {
      type: String as PropType<TooltipPosition>,
      default: 'bottom-right',
    },
    items: {
      type: Array as PropType<string[]>,
      default: () => defaultItems,
    },
  },
})
