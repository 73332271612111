import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_compose_side_button = _resolveComponent("compose-side-button")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, {
    class: "d-flex",
    position: _ctx.position
  }, {
    menu: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
        return (_openBlock(), _createBlock(_component_tm_dropdown_item, { key: i }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, {
              class: "distinct--text mr-2",
              name: "local_offer"
            }),
            _createElementVNode("span", null, _toDisplayString(item), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_compose_side_button, {
          icon: "sell",
          label: "Insert tag",
          "primary-color": _ctx.primaryColor
        }, null, 8, ["primary-color"])
      ])
    ]),
    _: 3
  }, 8, ["position"]))
}