
import { defineComponent, ref, watch, computed } from 'vue'
import ComposeMessageArea from '@/components/shared/compose/ComposeMessageArea.vue'
import ComposeSideButtonInsertTemplate
  from '@/components/shared/compose/sideButtons/ComposeSideButtonInsertTemplate.vue'
import ComposeSideButtonInsertTag from '@/components/shared/compose/sideButtons/ComposeSideButtonInsertTag.vue'
import ComposeSideButtonAttachFile from '@/components/shared/compose/sideButtons/ComposeSideButtonAttachFile.vue'
import ComposeFieldWrapper from '@/components/shared/compose/ComposeFieldWrapper.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'MessageEditor',
  components: {
    ComposeFieldWrapper,
    ComposeSideButtonAttachFile,
    ComposeSideButtonInsertTag,
    ComposeSideButtonInsertTemplate,
    ComposeMessageArea,
  },
  props: {
    hideAttachFile: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
    content: {
      type: String,
      default: 'Hi {First name}\n\nI just sent your Resume to your email.\nPlease REPLY back to me to CONFIRM you received your Resume OK.',
    },
    autofocus: {
      type: Boolean,
    },
  },
  emits: ['update:content'],
  setup(props) {
    const { isSmMax } = useBreakpoints()
    const hintValue = computed(() => props.hint || (isSmMax.value ? '143/918 • 1/6 • $15.88' : 'Characters: 143/918 • Parts: 1/6 • Cost: $15.88'))
    const contentValue = ref(props.content)

    watch(() => props.content, (newValue) => {
      contentValue.value = newValue
    })
    return {
      contentValue,
      hintValue,
    }
  },
})
