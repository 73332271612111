import type { ReportCardType } from '@/definitions/shared/templates/types'
import type {
  CampaignsDetailsRateType,
  DestinationsType,
  CampaignsDetailsDevice,
  CampaignsDetailsProgressInfoType
} from '@/definitions/campaigns/types'
import { rgbToHex, styleVars } from '@/compositions/styleVariables'

export const destinations: DestinationsType[] = [
  {
    country: {
      name: 'United States',
      id: 'us',
    },
    recipients: 246,
    numbers: [
      {
        number: '(823) 861-8832',
        label: 'US Support',
      },
      {
        number: '(823) 861-8835',
        label: 'US Support',
      },
      {
        number: '(823) 861-8837',
        label: 'US Support',
      },
      {
        number: '(823) 861-8830',
        label: 'US Support',
      },
    ],
  },
  {
    country: {
      name: 'United Kingdom',
      id: 'uk',
    },
    recipients: 15,
    numbers: [
      {
        number: '+44 117 2345678',
        label: 'UK Support',
      },
      {
        number: '+44 117 2346777',
        label: 'UK Support',
      },
      {
        number: '+44 117 2344674',
        label: 'UK Support',
      },
      {
        number: '+44 117 2345676',
        label: 'UK Support',
      },
    ],
  },
]

export const senderEmails: string[] = [
  'marketing@company.textmagic.com',
  'support@company.textmagic.com',
  'admin@company.textmagic.com',
]

export const senderEmailMessageTemplate = 'Copyright ©. {Current year}, {Company name}, All rights reserved. <br/><br/>Our mailing address is: <br/> {Сompany address} <br/><br/> Want to stop receiving these emails? <br/>{Unsubscribe link}'

export const campaignSmsDeliveryStats: ReportCardType[] = [
  {
    title: 'Recipients',
    counter: '10,234',
    percent: {
      value: '100%',
      change: 'blue',
    },
  },
  {
    title: 'Delivered',
    counter: '10,121',
    percent: {
      value: '97.5%',
      change: 'green',
    },
  },
  {
    title: 'Rejected',
    counter: '5',
    percent: {
      value: '1.4%',
      change: 'orange',
    },
  },
  {
    title: 'Failed',
    counter: '1',
    percent: {
      value: '0.1%',
      change: 'red',
    },
  },
  {
    title: 'Unsubscribed',
    counter: '24',
    percent: {
      value: '1.3%',
      change: 'gray',
    },
  },
]

export const campaignsSmsAnalyticsRates: CampaignsDetailsRateType[] = [
  {
    title: 'Response rate',
    btnTitle: 'View responses',
    percent: 12.8,
    link: { name: 'base.campaigns.sms.details.responses' },
    stats: [
      {
        name: 'Total responses',
        value: '1,422',
      },
      {
        name: 'Unresponded contacts',
        value: '8,699',
      },
      {
        name: 'First response time',
        value: '7 Aug, 12:21 pm',
      },
      {
        name: 'Last response time',
        value: '7 Aug, 3:30 pm',
      },
      {
        name: 'Avg. time to respond',
        value: '2m 30s',
      },
    ],
  },
  {
    title: 'Click rate',
    btnTitle: 'View clicks',
    percentColor: rgbToHex(styleVars.green500),
    percent: 20.4,
    stats: [
      {
        name: 'Total clicks',
        value: '2,473',
      },
      {
        name: 'Unique clicks',
        value: '2,121',
      },
      {
        name: 'First clicked',
        value: '7 Aug, 12:25 pm',
      },
      {
        name: 'Last clicked',
        value: '7 Aug, 3:29 pm',
      },
      {
        name: 'Avg. time to click',
        value: '0m 30s',
      },
    ],
  },
]

export const campaignSmsDetailsStats: ReportCardType[] = [
  {
    title: 'Total campaign cost',
    counter: '$141.54',
  },
  {
    title: 'Avg. cost per SMS',
    counter: '$0.83',
  },
  {
    title: 'Message parts count',
    counter: '2',
  },
  {
    title: 'Characters count',
    counter: '217',
    counterAfter: 'plain text',
  },
]

export const campaignEmailDeliveryStats: ReportCardType[] = [
  {
    title: 'Recipients',
    counter: '10,234',
    percent: {
      value: '100%',
      change: 'blue',
    },
  },
  {
    title: 'Delivered',
    counter: '9,802',
    percent: {
      value: '92.4%',
      change: 'green',
    },
  },
  {
    title: 'Bounces',
    counter: '120',
    percent: {
      value: '11.2%',
      change: 'orange',
    },
  },
  {
    title: 'Unsubscribes',
    counter: '44',
    percent: {
      value: '0.8%',
      change: 'red',
    },
  },
  {
    title: 'Spam reports',
    counter: '6',
    percent: {
      value: '0.2%',
      change: 'red',
    },
  },
]

export const campaignsEmailEngagement: CampaignsDetailsRateType[] = [
  {
    title: 'Open rate',
    percentColor: rgbToHex(styleVars.green500),
    btnTitle: 'View opens',
    percent: 62.8,
    stats: [
      {
        name: 'Total opens',
        value: '6,120',
      },
      {
        name: 'Unopened contacts',
        value: '3,682',
      },
      {
        name: 'First open time',
        value: '7 Aug, 12:21 pm',
      },
      {
        name: 'Last open time',
        value: '7 Aug, 3:30 pm',
      },
      {
        name: 'Avg. time to open',
        value: '2m 30s',
      },
    ],
  },
  {
    title: 'Click rate',
    btnTitle: 'View clicks',
    link: { name: 'base.campaigns.email.details.links' },
    percent: 16.2,
    stats: [
      {
        name: 'Total clicks',
        value: '1,622',
      },
      {
        name: 'Unclicked contacts',
        value: '8,299',
      },
      {
        name: 'First click time',
        value: '7 Aug, 12:21 pm',
      },
      {
        name: 'Last click time',
        value: '7 Aug, 3:30 pm',
      },
      {
        name: 'Avg. time to click',
        value: '2m 44s',
      },
    ],
  },
  {
    title: 'Response rate',
    btnTitle: 'View responses',
    percentColor: rgbToHex(styleVars.orange500),
    link: { name: 'base.campaigns.email.details.responses' },
    percent: 8.4,
    stats: [
      {
        name: 'Total responses',
        value: '822',
      },
      {
        name: 'Unresponded contacts',
        value: '9,139',
      },
      {
        name: 'First response time',
        value: '7 Aug, 12:21 pm',
      },
      {
        name: 'Last response time',
        value: '7 Aug, 3:30 pm',
      },
      {
        name: 'Avg. time to respond',
        value: '3m 12s',
      },
    ],
  },
]

export const campaignsEmailDevicesOpens: CampaignsDetailsDevice[] = [
  {
    title: 'Desktop',
    icon: 'desktop_windows',
    percent: 55.8,
    counter: '5,872',
    color: 'blue',
  },
  {
    title: 'Tablet',
    icon: 'tablet_mac',
    percent: 32.5,
    counter: '3,443',
    color: 'green',
  },
  {
    title: 'Mobile',
    icon: 'tablet_mac',
    percent: 11.7,
    counter: '1,222',
    color: 'orange',
  },
]

export const campaignsEmailDevicesClicks: CampaignsDetailsDevice[] = [
  {
    title: 'Desktop',
    icon: 'desktop_windows',
    percent: 44.3,
    counter: '2,872',
    color: 'blue',
  },
  {
    title: 'Tablet',
    icon: 'tablet_mac',
    percent: 16.7,
    counter: '765',
    color: 'green',
  },
  {
    title: 'Mobile',
    icon: 'tablet_mac',
    percent: 39,
    counter: '1,222',
    color: 'orange',
  },
]

export const campaignsLinksTableData = [
  {
    link: 'www.novatech.com',
    total: '400 (24.7%)',
    unique: '203 (12.1%)',
  },
  {
    link: 'www.novatech.com/new-product',
    total: '320 (19.7%)',
    unique: '152 (9.9%)',
  },
  {
    link: 'www.novatech.com/newsletter-signup',
    total: '250 (15.4%)',
    unique: '134 (7.7%)',
  },
  {
    link: 'www.facebook.com/novatech',
    total: '150 (9.2%)',
    unique: '80 (4.6%)',
  },
  {
    link: 'www.novatech.com/blog/industry-trends',
    total: '120 (7.4%)',
    unique: '58 (3.8%)',
  },
  {
    link: 'www.novatech.com/contact',
    total: '100 (6.2%)',
    unique: '44 (3.0%)',
  },
]

export const campaignsEmailDestination = [
  {
    country: {
      id: 'us',
      name: 'United States',
    },
    value: '3,210',
  },
  {
    country: {
      id: 'ca',
      name: 'Canada',
    },
    value: '2,291',
  },
  {
    country: {
      id: 'uk',
      name: 'United Kingdom',
    },
    value: '1,827',
  },
  {
    country: {
      id: 'fr',
      name: 'France',
    },
    value: '1,410',
  },
  {
    country: {
      id: 'de',
      name: 'Germany',
    },
    value: '698',
  },
]

export const campaignsEmailEngagementEmpty = [
  {
    title: 'Open rate',
    text: 'Open rate will be shown here once data is available.',
  },
  {
    title: 'Click rate',
    text: 'Click rate will be shown here once data is available.',
  },
  {
    title: 'Response rate',
    text: 'Response rate will be shown here once data is available.',
  },
]

export const campaignsEmailProgressInfos: CampaignsDetailsProgressInfoType[] = [
  {
    title: 'Emails sent',
    value: '8,500',
  },
  {
    title: 'Total emails',
    value: '10,234',
  },
]

export const campaignsSmsProgressInfos: CampaignsDetailsProgressInfoType[] = [
  {
    title: 'Messages sent',
    value: '8,500',
  },
  {
    title: 'Total messages',
    value: '10,234',
  },
]

export const campaignsWordCloud = [
  ['addressed immediately', 19],
  ['email', 3],
  ['gentleman called', 14],
  ['texts', 23],
  ['ticket', 8],
  ['immediately', 11],
  ['finally', 16],
  ['reach', 16],
  ['gentleman', 35],
  ['responded', 26],
  ['support', 23],
  ['mentioned', 33],
  ['called', 18],
  ['waiting', 8],
  ['gentleman mentioned', 4],
]
