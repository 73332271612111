
import { defineComponent, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowBottomBar from '@/components/pages/campaigns/FlowBottomBar.vue'
import useSmsFlow from '@/compositions/smsFlow'

export default defineComponent({
  components: {
    TmButton,
    FlowBottomBar,
  },
  setup() {
    const {
      recipients,
      message,
      schedule,
      addRecipients,
      addMessage,
      addSendTime,
    } = useSmsFlow()
    const allFilled = computed(() => (recipients.value.length === 0) || !message.value || !schedule.value)
    const buttonMessage = computed(() => (schedule.value === 'later' ? 'Schedule' : 'Send') + (message.value ? ' 10,234 messages' : ' campaign'))
    return {
      recipients,
      message,
      schedule,
      addRecipients,
      addMessage,
      addSendTime,
      allFilled,
      buttonMessage,
    }
  },
})
