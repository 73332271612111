import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ac89b0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-end" }
const _hoisted_2 = { class: "mr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_compose_hint = _resolveComponent("compose-hint")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!
  const _component_compose_side_button_contacts = _resolveComponent("compose-side-button-contacts")!
  const _component_compose_side_button_lists = _resolveComponent("compose-side-button-lists")!
  const _component_compose_side_button_segments = _resolveComponent("compose-side-button-segments")!
  const _component_compose_side_button_frequently_sent = _resolveComponent("compose-side-button-frequently-sent")!
  const _component_compose_field_wrapper = _resolveComponent("compose-field-wrapper")!

  return (_openBlock(), _createBlock(_component_compose_field_wrapper, null, {
    "side-buttons": _withCtx(() => [
      _createVNode(_component_compose_side_button_contacts),
      _createVNode(_component_compose_side_button_lists),
      _createVNode(_component_compose_side_button_segments),
      _createVNode(_component_compose_side_button_frequently_sent)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_field, {
        modelValue: _ctx.recipient,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.recipient) = $event)),
        options: _ctx.recipientOptions,
        "onUpdate:options": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.recipientOptions) = $event)),
        placeholder: _ctx.placeholder,
        type: "tagsRecipient",
        class: "tags-recipient",
        "new-line-input": ""
      }, null, 8, ["modelValue", "options", "placeholder"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_dropdown, { class: "ml-auto" }, {
          menu: _withCtx(() => [
            _createVNode(_component_tm_dropdown_item, {
              label: "Copy",
              icon: "content_copy"
            }),
            _createVNode(_component_tm_dropdown_item, {
              label: "Clear all",
              icon: "delete",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.recipient = []))
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_compose_hint, { class: "pointer" }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, "Recipients: " + _toDisplayString(_ctx.recipientNumber), 1),
                _createVNode(_component_tm_icon, { name: "tmi-arrow-drop-down-sharp" })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}