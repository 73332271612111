
import { defineComponent } from 'vue'
import type { SizeProp } from '@/definitions/shared/types'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'TmAlert',
  components: {
    TmButton,
  },
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    noBorderRadius: {
      type: Boolean,
    },
    alignButtonTop: {
      type: Boolean,
    },
    alignCenter: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<'' | 'small'>,
      default: '',
    },
    showCloseBtn: {
      type: Boolean,
    },
  },
  emits: ['close'],
  setup(props, context) {
    const hasAlertButtonSlot = context.slots['alert-button']

    return {
      hasAlertButtonSlot,
    }
  },
})
