
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStepPreview from '@/components/shared/templates/FlowStepPreview.vue'
import Schedule from '@/components/pages/campaigns/Schedule.vue'
import useSmsFlow from '@/compositions/smsFlow'

export default defineComponent({
  components: {
    TmButton,
    FlowStepPreview,
    Schedule,
  },
  setup() {
    const { schedule, updateSchedule, finishStep } = useSmsFlow()
    const scheduleValue = ref(schedule.value.slice(0))

    const onDiscard = () => {
      scheduleValue.value = schedule.value.slice(0)
    }
    const stepFunction = () => {
      updateSchedule(scheduleValue.value)
      finishStep(4)
    }

    return {
      schedule,
      scheduleValue,
      onDiscard,
      stepFunction,
    }
  },
})
