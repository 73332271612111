
import { computed, defineComponent } from 'vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  name: 'TmTemplate',
  props: {
    content: {
      type: String,
      default: '',
    },
    ellipsis: {
      type: Boolean,
    },
  },
  setup(props) {
    const { isErrorMode } = useModes()
    const chipClass = computed(() => isErrorMode.value ? 'tm-list-chip--error' : '')
    const internalContent = computed(() => {
      return props.content
        .replace(/({(.*?)})/g, `<span class="${chipClass.value}">$2</span>`)
        .replace(/\n/g, props.ellipsis ? '' : '<br>')
    })

    return {
      internalContent,
    }
  },
})
