import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_compose_side_button = _resolveComponent("compose-side-button")!

  return (_openBlock(), _createBlock(_component_compose_side_button, {
    icon: "person",
    label: "Contacts",
    onClick: _ctx.openModalNewRecipients
  }, null, 8, ["onClick"]))
}