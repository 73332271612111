
import { computed, defineComponent, watch, ref } from 'vue'
import { useScroll } from '@/compositions/rootScroll'
import TmButton from '@/components/shared/TmButton.vue'
import { styleVars } from '@/compositions/styleVariables'

export default defineComponent({
  name: 'FlowStepPreview',
  components: {
    TmButton,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    nextActiveStep: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    continueButton: {
      type: String,
      default: 'Save changes',
    },
    editButton: {
      type: String,
    },
    icon: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    withError: {
      type: Boolean,
    },
    finished: {
      type: Boolean,
    },
    stepFunction: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['step-change', 'step-active'],
  setup(props, context) {
    const { rootScroll } = useScroll()
    const root = ref<HTMLDivElement | null>(null)

    const isActive = computed(() => props.step === props.currentStep)
    const isFinished = computed(() => props.finished)
    const isWithError = computed(() => props.withError)
    const isNextActive = computed(() => props.step === props.nextActiveStep)

    const alignTop = (el: HTMLElement) => {
      if (!el || !rootScroll.value) return
      const scrollGap = 8
      setTimeout(() => {
        const { top } = el.getBoundingClientRect()
        rootScroll.value.scrollTop = rootScroll.value.scrollTop + top - styleVars.headerHeight - scrollGap
      }, 0)
    }

    const stepIcon = computed(() => (isFinished.value && !isActive.value) ? (isWithError.value ? 'warning' : 'done') : props.icon)
    const stepIconClass = computed(() => (isFinished.value && !isActive.value) ? (isWithError.value ? 'warning--text' : 'success--text') : 'primary--text')

    watch(isActive, async (newVal) => {
      if (newVal && root.value) {
        context.emit('step-active')
        alignTop(root.value)
      }
    })

    return {
      root,
      isActive,
      isFinished,
      isWithError,
      isNextActive,
      stepIcon,
      stepIconClass,
    }
  },
})
