import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f76bbfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "compose-message-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_compose_hint = _resolveComponent("compose-hint")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_tm_field, {
        modelValue: _ctx.contentValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contentValue) = $event)),
        type: "textarea",
        autogrow: "",
        autofocus: _ctx.autofocus
      }, null, 8, ["modelValue", "autofocus"])
    ]),
    (_ctx.hint)
      ? (_openBlock(), _createBlock(_component_compose_hint, { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.hint), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}