import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cfb936fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-alert__content" }
const _hoisted_2 = { class: "tm-alert__content__container" }
const _hoisted_3 = {
  key: 0,
  class: "subhead-semi-bold-16 emphasize--text mb-1"
}
const _hoisted_4 = { class: "body-text-regular-14 emphasize--text lh-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-alert", {
      'tm-alert--small': _ctx.size === 'small',
      'tm-alert--no-border-radius': _ctx.noBorderRadius
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["tm-alert__wrapper", { 'tm-alert__wrapper--align-center': _ctx.alignCenter }])
    }, [
      _renderSlot(_ctx.$slots, "icon", {}, () => [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_tm_icon, {
              key: 0,
              class: "tm-alert__icon",
              name: _ctx.icon,
              size: "xLarge"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true)
      ], true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]),
        (_ctx.hasAlertButtonSlot || _ctx.showCloseBtn)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["tm-alert__content__button", {
            'tm-alert__content__button--top': _ctx.alignButtonTop,
          }])
            }, [
              _renderSlot(_ctx.$slots, "alert-button", {}, () => [
                (_ctx.showCloseBtn)
                  ? (_openBlock(), _createBlock(_component_tm_button, {
                      key: 0,
                      size: "small",
                      class: "tm-alert__close-btn",
                      flat: "",
                      color: "transparent",
                      "icon-only": "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_tm_icon, {
                          name: "close",
                          size: "small",
                          class: "neutral--text"
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], true)
            ], 2))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 2))
}