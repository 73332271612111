import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_compose_message_area = _resolveComponent("compose-message-area")!
  const _component_compose_side_button_insert_template = _resolveComponent("compose-side-button-insert-template")!
  const _component_compose_side_button_insert_tag = _resolveComponent("compose-side-button-insert-tag")!
  const _component_compose_side_button_attach_file = _resolveComponent("compose-side-button-attach-file")!
  const _component_compose_field_wrapper = _resolveComponent("compose-field-wrapper")!

  return (_openBlock(), _createBlock(_component_compose_field_wrapper, null, {
    "side-buttons": _withCtx(() => [
      _createVNode(_component_compose_side_button_insert_template),
      _createVNode(_component_compose_side_button_insert_tag),
      (!_ctx.hideAttachFile)
        ? (_openBlock(), _createBlock(_component_compose_side_button_attach_file, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_compose_message_area, {
        content: _ctx.contentValue,
        hint: _ctx.hintValue,
        autofocus: _ctx.autofocus,
        "onUpdate:content": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:content', $event)))
      }, null, 8, ["content", "hint", "autofocus"]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}