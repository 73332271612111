import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55d310cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sms-flow" }
const _hoisted_2 = { class: "flex-grow-1 w100pr" }
const _hoisted_3 = { class: "sms-flow__preview" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flow_step_list = _resolveComponent("flow-step-list")!
  const _component_sms_flow_preview = _resolveComponent("sms-flow-preview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_flow_step_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent('sms-flow-step' + step.step), {
              key: step.step,
              step: step.step,
              title: step.title,
              icon: step.icon,
              "edit-button": step.editButton,
              disabled: step.disabled,
              finished: step.finished,
              "current-step": _ctx.currentStep,
              "next-active-step": _ctx.nextActiveStep,
              onStepChange: _ctx.onStepChange
            }, null, 40, ["step", "title", "icon", "edit-button", "disabled", "finished", "current-step", "next-active-step", "onStepChange"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_sms_flow_preview)
    ])
  ]))
}