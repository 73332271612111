
import { defineComponent, ref, watch } from 'vue'
import ComposeHint from '@/components/shared/compose/ComposeHint.vue'
// import TmTemplate from '@/components/shared/TmTemplate.vue'

export default defineComponent({
  name: 'ComposeMessageArea',
  components: {
    // TmTemplate,
    ComposeHint,
  },
  props: {
    hint: {
      type: String,
    },
    content: {
      type: String,
    },
    autofocus: {
      type: Boolean,
    },
  },
  emits: ['update:content'],
  setup(props, context) {
    const contentValue = ref(props.content)

    watch(() => props.content, (newValue) => {
      contentValue.value = newValue
    })
    watch(() => contentValue.value, (newValue) => {
      context.emit('update:content', newValue)
    })
    return {
      contentValue,
    }
  },
})
