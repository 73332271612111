
import { defineComponent, ref } from 'vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTemplate from '@/components/shared/TmTemplate.vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'
import FlowStepPreview from '@/components/shared/templates/FlowStepPreview.vue'
import MessageEditor from '@/components/shared/compose/MessageEditor.vue'
import useSmsFlow from '@/compositions/smsFlow'

export default defineComponent({
  components: {
    TmAlert,
    TmButton,
    TmTemplate,
    TmColoredUl,
    FlowStepPreview,
    MessageEditor,
  },
  setup() {
    const { message, updateMessage, finishStep } = useSmsFlow()
    const messageValue = ref(message.value.slice(0))
    const onDiscard = () => {
      messageValue.value = message.value.slice(0)
    }
    const stepFunction = () => {
      updateMessage(messageValue.value)
      finishStep(3)
    }
    const showAlert = ref(true)
    return {
      message,
      messageValue,
      onDiscard,
      stepFunction,
      showAlert,
    }
  },
})
