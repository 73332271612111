
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'

export default defineComponent({
  components: {
    TmButton,
    TmScrollbar,
  },
  props: {
    number: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  emits: ['add-message'],
})
