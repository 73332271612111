import { useModals } from '@/compositions/modals'

type UseComposeOpenModal = () => {
  openModalNewRecipients: () => void
  openModalListsTable: () => void
  openModalSegmentsTable: () => void
  openModalInsertTemplate: () => void
  openModalCreateTemplate: () => void
  openModalPreview: (total: number, isSchedule: boolean, dropUpName?: string) => void
  openModalAudioRecordings: () => void
  openModalAttachFile: () => void
}

const useComposeOpenModal: UseComposeOpenModal = () => {
  const { openModal } = useModals()

  const openModalNewRecipients = () => {
    openModal('addExistingContacts', {
      addButtonLabel: 'recipients',
    })
  }

  const openModalListsTable = () => {
    openModal('contactsListsTable')
  }

  const openModalSegmentsTable = () => {
    openModal('segmentsTableModal', {
      title: 'Segments',
    })
  }

  const openModalInsertTemplate = () => {
    openModal('composeInsertTemplate')
  }

  const openModalCreateTemplate = () => {
    openModal('templatesTemplate', {
      btnText: 'Save',
      modalTitle: 'Create new template',
    })
  }

  const openModalPreview = (total = 1, isSchedule = false, dropUpName?: string) => {
    openModal('composePreview', {
      total,
      isSchedule,
      dropUpName,
    })
  }

  const openModalAudioRecordings = () => {
    openModal('composeAudioRecordings')
  }

  const openModalAttachFile = () => {
    openModal('attachFile')
  }

  return {
    openModalNewRecipients,
    openModalListsTable,
    openModalSegmentsTable,
    openModalInsertTemplate,
    openModalCreateTemplate,
    openModalPreview,
    openModalAudioRecordings,
    openModalAttachFile,
  }
}

export default useComposeOpenModal
