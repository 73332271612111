
import { defineComponent, computed } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import SmsFlow from '@/components/pages/campaigns/smsFlow/SmsFlow.vue'
import SmsFlowBottomBar from '@/components/pages/campaigns/smsFlow/SmsFlowBottomBar.vue'

export default defineComponent({
  components: {
    PageContent,
    DetailsHero,
    SmsFlow,
    SmsFlowBottomBar,
  },
  setup() {
    const breadcrumbs = computed(() => [
      { label: 'Campaign', url: { name: 'base.campaigns.sms' } },
      { label: 'New Mercurial Vapor X' },
    ])

    const infos = [
      { label: 'Text message campaign' },
      { label: 'Draft saved 1 min ago' },
    ]

    return {
      breadcrumbs,
      infos,
    }
  },
})
