
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'ComposeSideButton',
  components: { TmButton },
  props: {
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    primaryColor: {
      type: Boolean,
    },
  },
  setup() {
    const { isSmMax } = useBreakpoints()

    return {
      isSmMax,
    }
  },
})
