import { ref } from 'vue'

type StepsType = {
  step: number;
  title: string;
  icon: string;
  editButton: string;
  active?: boolean;
  disabled?: boolean;
  finished?: boolean;
}

type DestinationSelectedType = {
  number: string;
  label: string;
}

type UseSmsFlowCompose = {
  recipients: any;
  currentStep: any;
  nextActiveStep: any;
  steps: any;
  destinationSelected: any;
  message: any;
  schedule: any;
  updateRecipients: (value: any) => void;
  updateDestinationSelected: (value: any) => void;
  updateMessage: (value: any) => void;
  updateSchedule: (value: any) => void;
  updateCurrentStep: (value: number) => void;
  closeSteps: () => void;
  updateNextActiveStep: () => void;
  finishStep: (value: number) => void;
  addRecipients: () => void;
  addMessage: () => void;
  addSendTime: () => void;
}

const recipients = ref<any>([])
const currentStep = ref<number>(0)
const nextActiveStep = ref<number>(1)
const steps = ref<StepsType[]>([
  {
    step: 1,
    title: 'To',
    icon: 'tmi-person-group-outline',
    editButton: 'recipients',
  },
  {
    step: 2,
    title: 'From',
    icon: 'tmi-contacts-outline',
    editButton: 'numbers',
    disabled: !recipients.value.length,
  },
  {
    step: 3,
    title: 'Message',
    icon: 'tmi-chat-outline',
    editButton: 'message',
  },
  {
    step: 4,
    title: 'Send time',
    icon: 'schedule',
    editButton: 'send time',
  },
])
const destinationSelected = ref<DestinationSelectedType[]>([
  {
    number: '(823) 861-8832',
    label: 'US Support',
  },
  {
    number: '+44 117 2345678',
    label: 'UK Support',
  },
])
const message = ref('')
const schedule = ref('')

const useSmsFlow = (): UseSmsFlowCompose => {
  const updateRecipients = (value: any) => {
    recipients.value = [...value]
    steps.value[1].disabled = !value.length
  }
  const updateDestinationSelected = (value: any) => {
    destinationSelected.value = [...value]
  }
  const updateMessage = (value: any) => {
    message.value = value.slice(0)
  }
  const updateSchedule = (value: any) => {
    schedule.value = value.slice(0)
  }
  const updateCurrentStep = (value: number) => {
    currentStep.value = value
  }
  const closeSteps = () => {
    updateCurrentStep(0)
  }
  const updateNextActiveStep = () => {
    if (recipients.value.length === 0) {
      nextActiveStep.value = 1
    } else {
      if (!message.value) {
        nextActiveStep.value = 3
      } else {
        if (!schedule.value) {
          nextActiveStep.value = 4
        } else {
          nextActiveStep.value = 0
        }
      }
    }
  }
  const finishStep = (value: number) => {
    steps.value[value - 1].finished = true
    closeSteps()
    updateNextActiveStep()
  }
  const addRecipients = () => {
    updateCurrentStep(1)
  }
  const addMessage = () => {
    updateCurrentStep(3)
  }
  const addSendTime = () => {
    updateCurrentStep(4)
  }

  return {
    steps,
    recipients,
    currentStep,
    nextActiveStep,
    destinationSelected,
    message,
    schedule,
    updateRecipients,
    updateDestinationSelected,
    updateMessage,
    updateSchedule,
    updateCurrentStep,
    closeSteps,
    updateNextActiveStep,
    finishStep,
    addRecipients,
    addMessage,
    addSendTime,
  }
}

export default useSmsFlow
