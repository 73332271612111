
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmButtonGroup from '@/components/shared/TmButtonGroup.vue'

export default defineComponent({
  components: {
    TmButton,
    TmButtonGroup,
  },
  emits: ['input'],
  setup() {
    const weekDays = ref([
      {
        text: 'Sun',
        value: false,
      },
      {
        text: 'Mon',
        value: false,
      },
      {
        text: 'Tue',
        value: false,
      },
      {
        text: 'Wed',
        value: false,
      },
      {
        text: 'Thu',
        value: false,
      },
      {
        text: 'Fri',
        value: false,
      },
      {
        text: 'Sat',
        value: false,
      },
    ])
    return {
      weekDays,
    }
  },
})
