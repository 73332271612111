
import { defineComponent, ref, watch, computed } from 'vue'
import ComposeHint from '@/components/shared/compose/ComposeHint.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { recipientOptions } from '@/definitions/_general/_data/optionsList'
import ComposeSideButtonContacts from '@/components/shared/compose/sideButtons/ComposeSideButtonContacts.vue'
import ComposeSideButtonLists from '@/components/shared/compose/sideButtons/ComposeSideButtonLists.vue'
import ComposeSideButtonSegments from '@/components/shared/compose/sideButtons/ComposeSideButtonSegments.vue'
import ComposeSideButtonFrequentlySent from '@/components/shared/compose/sideButtons/ComposeSideButtonFrequentlySent.vue'
import ComposeFieldWrapper from '@/components/shared/compose/ComposeFieldWrapper.vue'

export default defineComponent({
  name: 'RecipientsEditor',
  components: {
    ComposeFieldWrapper,
    ComposeSideButtonFrequentlySent,
    ComposeSideButtonLists,
    ComposeSideButtonSegments,
    ComposeSideButtonContacts,
    TmDropdownItem,
    TmDropdown,
    ComposeHint,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Start typing a number or contact name',
    },
    recipients: {
      type: Array,
      default: () => [...recipientOptions.slice(0, 3)],
    },
  },
  emits: ['update:recipientValue'],
  setup(props, context) {
    const recipient = ref(props.recipients)

    const recipientNumber = computed(() => {
      const singleRecipients = recipient.value.filter((item: any) => item.type === 'person').length
      const listRecipients = recipient.value.filter((item: any) => item.type === 'list').reduce((acc: any, item: any) => acc + item.shortHint, 0)
      return singleRecipients + Number(listRecipients)
    })

    watch(() => props.recipients, (newValue) => {
      recipient.value = newValue
    })

    watch(() => recipient.value, (newValue) => {
      context.emit('update:recipientValue', newValue)
    })

    return {
      recipientOptions,
      recipient,
      recipientNumber,
    }
  },
})
