import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-351a1114"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "send-field__option" }
const _hoisted_2 = { class: "truncate flex-grow-1" }
const _hoisted_3 = { class: "distinct--text" }
const _hoisted_4 = { class: "send-field__option" }
const _hoisted_5 = { class: "truncate flex-grow-1" }
const _hoisted_6 = { class: "distinct--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_flag = _resolveComponent("tm-flag")!
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createBlock(_component_tm_field, {
    modelValue: _ctx.number,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.number) = $event)),
    options: _ctx.item.numbers,
    type: "select",
    clearable: false,
    class: "send-field"
  }, {
    option: _withCtx(({ opt }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_flag, {
          country: _ctx.item.country,
          class: "flex-grow-0"
        }, null, 8, ["country"]),
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(opt.number) + " ", 1),
          _createElementVNode("span", _hoisted_3, "(" + _toDisplayString(opt.label) + ")", 1)
        ])
      ])
    ]),
    "selected-item": _withCtx(({ opt }) => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_tm_flag, {
          country: _ctx.item.country,
          class: "flex-grow-0"
        }, null, 8, ["country"]),
        _createElementVNode("div", _hoisted_5, [
          _createTextVNode(_toDisplayString(opt.number) + " ", 1),
          _createElementVNode("span", _hoisted_6, "(" + _toDisplayString(opt.label) + ")", 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "options"]))
}