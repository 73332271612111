
import { defineComponent, ref, watch } from 'vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import useSmsFlow from '@/compositions/smsFlow'

export default defineComponent({
  components: {
    TmFlag,
  },
  props: {
    item: {
      type: Object,
    },
    listNumber: {
      type: Number,
      default: 0,
    },
  },
  emits: ['updateSelectedNumbers'],
  setup(props, context) {
    const { destinationSelected } = useSmsFlow()
    const number = ref(destinationSelected.value[props.listNumber])

    watch(() => number.value, (newValue) => {
      context.emit('updateSelectedNumbers', newValue)
    })

    return {
      number,
      destinationSelected,
    }
  },
})
