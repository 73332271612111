
import { defineComponent } from 'vue'
import ComposeSideButton from '@/components/shared/compose/sideButtons/ComposeSideButton.vue'
import useComposeOpenModal from '@/compositions/compose/useComposeOpenModal'

export default defineComponent({
  name: 'ComposeSideButtonInsertTemplate',
  components: { ComposeSideButton },
  setup() {
    const { openModalInsertTemplate } = useComposeOpenModal()

    return {
      openModalInsertTemplate,
    }
  },
})
