import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createBlock(_component_tm_button, {
    "text-link": !_ctx.isSmMax,
    "icon-only": _ctx.isSmMax,
    flat: _ctx.isSmMax,
    size: _ctx.isSmMax ? 'small' : '',
    color: _ctx.isSmMax ? 'transparent' : ''
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_icon, {
        class: "primary--text",
        name: _ctx.icon,
        left: !_ctx.isSmMax,
        size: _ctx.isSmMax ? 'xLarge' : 'medium'
      }, null, 8, ["name", "left", "size"]),
      (!_ctx.isSmMax)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["lh-22 blue-on-hover", _ctx.primaryColor ? 'primary--text' : 'neutral--text'])
          }, _toDisplayString(_ctx.label), 3))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["text-link", "icon-only", "flat", "size", "color"]))
}