
import { defineComponent, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { formatNumber } from '@/services/utils'
import useSmsFlow from '@/compositions/smsFlow'
import PhonePreview from '@/components/shared/templates/PhonePreview.vue'

export default defineComponent({
  components: {
    PhonePreview,
    TmButton,
  },
  setup() {
    const { recipients, destinationSelected, message, addMessage } = useSmsFlow()
    const currentPreview = computed(() => recipients.value.length > 0 ? 1 : 0)
    const currentPreviewLabel = computed(() => recipients.value[0].label)
    const number = computed(() => recipients.value.length > 0 ? destinationSelected.value[0].number : '')

    return {
      recipients,
      currentPreview,
      currentPreviewLabel,
      number,
      message,
      addMessage,
      formatNumber,
    }
  },
})
