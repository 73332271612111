
import { computed, defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmWeekDaysSelect from '@/components/shared/TmWeekDaysSelect.vue'
import { endOptions, firstLastOptions, months, repeatOptions, weekDays } from '@/definitions/_general/_data/frequencyOptions'

export default defineComponent({
  name: 'TmRepeatForm',
  components: {
    TmFormLine,
    TmWeekDaysSelect,
  },
  props: {
    state: {
      type: Object,
    },
    label: {
      type: String,
      default: 'Repeat',
    },
  },
  setup(props) {
    const monthDaysValue = ref(1)
    const monthDays = ref<number[]>([])
    for (let i = 1; i <= 31; i++) {
      monthDays.value.push(i)
    }
    const firstLastTypesValue = ref(firstLastOptions[0])
    const weekDaysValue = ref(weekDays[0])
    const monthsValue = ref(months[0])
    const repeatMonthly = ref('month-day')
    const repeatYearly = ref('year-day')
    const date = ref('22 Jan 2021')

    const isNone = computed(() => props.state?.currentRepeat.value === 'none')
    const isHourly = computed(() => props.state?.currentRepeat.value === 'hourly')
    const isDaily = computed(() => props.state?.currentRepeat.value === 'daily')
    const isWeekly = computed(() => props.state?.currentRepeat.value === 'weekly')
    const isMonthly = computed(() => props.state?.currentRepeat.value === 'monthly')
    const isYearly = computed(() => props.state?.currentRepeat.value === 'yearly')
    const notNoneOrYearly = computed(() => !isNone.value && !isYearly.value)

    const isEndAfter = computed(() => props.state?.currentEnd.value === 'after')
    const isEndOnDate = computed(() => props.state?.currentEnd.value === 'date')

    return {
      repeatOptions,
      endOptions,
      firstLastOptions,
      weekDays,
      months,

      isNone,
      isHourly,
      isDaily,
      isWeekly,
      isMonthly,
      isYearly,
      notNoneOrYearly,
      isEndAfter,
      isEndOnDate,

      monthDaysValue,
      monthDays,
      firstLastTypesValue,
      weekDaysValue,
      monthsValue,
      repeatMonthly,
      repeatYearly,
      date,
    }
  },
})
