export const repeatOptions = [
  { text: 'Doesn’t repeat', value: 'none' },
  { text: 'Hourly', value: 'hourly', unit: 'hour', pluralUnit: 'hours' },
  { text: 'Daily', value: 'daily', unit: 'day', pluralUnit: 'days' },
  { text: 'Weekly', value: 'weekly', unit: 'week', pluralUnit: 'weeks' },
  { text: 'Monthly', value: 'monthly', unit: 'month', pluralUnit: 'months' },
  { text: 'Yearly', value: 'yearly' },
]

export const endOptions = [
  { text: 'Never', value: 'never' },
  { text: 'After', value: 'after' },
  { text: 'On date', value: 'date' },
]

export const firstLastOptions = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Last',
]

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Day',
  'Weekday',
  'Weekend day',
]

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
